export default {
	webUrl:'https://api.yunfeitong.com.cn',//域名
	ImgUrl:'https://api.yunfeitong.com.cn',//所有图片地址得前缀域名
	// 行业分类
	whiteListApi:[// 白名单
		//不要验证token的 
		'/','/home','/spot','/spotDetail','/enterprise','/enterpriseDetail',
		'/bidding','/biddingDetail','/message','/messageDetail','/DataService','/DataServiceDetail','/login','/register','/auction','/auctionDetail'
	],
	// 左侧导航栏菜单路由
	navRouter:[
		{
			path:'/Centre/my',
			meta: {title: '会员中心', icon: 'icon-huiyuanzhongxin', inSide: true},
			name:'my',
		},
		{
			path:'/Centre/personage',
			meta: {title: '个人资料', icon: 'icon-tuichu', inSide: true},
			name:'personage',
		},
		{
			path:'/Centre/editPassword',
			meta: {title: '修改密码', icon: 'icon-xiugaimima', inSide: true},
			name:'editPassword',
		},
		{
			path:'/Centre/verify',
			meta: {title: '身份证验证', icon: 'icon-shenfenyanzheng', inSide: true},
			name:'verify',
		},
		{
			path:'/Centre/submitService',
			meta: {title: '数据服务', icon: 'icon-tuichu', inSide: true},
			name:'',
		},
		{
			path:'',
			meta: {title: '退出', icon: 'icon-tuichu', inSide: true},
			name:'',
		},
		
		// {
		// 	path:'/Centre/management',
		// 	meta: {title: '账号管理', icon: '', },
		// 	name:'management',
		// 	chilren:[
		// 		{
		// 			path:'/Centre/management/one',
		// 			meta: {title: '账号管理1', icon: '', },
		// 			name:'one',
		// 		}
		// 	]
		// }
	],
	industry:[
		{text:'不限',id:0,isActive:true},
		{text:'冶金设备',id:1,isActive:false},
		{text:'机床设备',id:2,isActive:false},
		{text:'化工设备',id:3,isActive:false},
		{text:'食品设备',id:4,isActive:false},
		{text:'医药设备',id:5,isActive:false},
		{text:'矿山设备',id:6,isActive:false},
		{text:'电力设备',id:7,isActive:false},
		{text:'工程设备',id:8,isActive:false},
		{text:'水泥设备',id:9,isActive:false},
		{text:'建材设备',id:10,isActive:false},
		{text:'包装设备',id:11,isActive:false},
		{text:'造纸设备',id:12,isActive:false},
		{text:'印刷设备',id:13,isActive:false},
		{text:'电子设备',id:14,isActive:false},
		{text:'服装设备',id:15,isActive:false},
		{text:'纺织设备',id:16,isActive:false},
		{text:'橡胶塑料设备',id:17,isActive:false},
		{text:'农业设备',id:18,isActive:false},
		{text:'制冷设备',id:19,isActive:false},
		{text:'酒店设备',id:20,isActive:false},
		{text:'交通运输',id:21,isActive:false},
		{text:'环保设备',id:22,isActive:false},
		{text:'仪器仪表',id:23,isActive:false},
		{text:'传动设备',id:24,isActive:false},
		{text:'锅炉',id:25,isActive:false},
		{text:'钢结构厂房',id:26,isActive:false},
		{text:'正厂转让',id:27,isActive:false},
		{text:'其他设备',id:28,isActive:false},
		{text:'拆除改造',id:29,isActive:false},
		{text:'拆除拆解',id:30,isActive:false},
		{text:'废金属',id:31,isActive:false},
		{text:'硬质合金',id:32,isActive:false},
		{text:'废纸',id:33,isActive:false},
		{text:'废塑料',id:34,isActive:false},
		{text:'废橡胶',id:35,isActive:false},
		{text:'废油',id:36,isActive:false},
		{text:'食品',id:37,isActive:false},
		{text:'树木',id:38,isActive:false},
		{text:'配件备件',id:39,isActive:false},
		{text:'废玻璃',id:40,isActive:false},
		{text:'旧纺织皮革',id:41,isActive:false},
		{text:'其他物资',id:42,isActive:false},
		{text:'船舶',id:43,isActive:false},
		{text:'电池',id:44,isActive:false},
	],
	homeIndustry:[//二手设备
		{text:'化工设备',id:3,isActive:false},
		{text:'食品设备',id:4,isActive:false},
		{text:'医药设备',id:5,isActive:false},
		{text:'冶金设备',id:1,isActive:false},
		{text:'矿山设备',id:6,isActive:false},
		{text:'电力设备',id:7,isActive:false},
		{text:'工程设备',id:8,isActive:false},
		{text:'水泥设备',id:9,isActive:false},
		{text:'建材设备',id:10,isActive:false},
		{text:'包装设备',id:11,isActive:false},
		{text:'造纸设备',id:12,isActive:false},
		{text:'机床设备',id:2,isActive:false},
	],
	homeIndustryFJ:[//废旧再生
		{text:'废金属',id:31,isActive:false},
		{text:'硬质合金',id:32,isActive:false},
		{text:'废纸',id:33,isActive:false},
		{text:'废塑料',id:34,isActive:false},
		{text:'废油',id:36,isActive:false},
	],
	homeIndustryCC:[//拆除拆解
		{text:'拆除改造',id:29,isActive:false},
		{text:'拆除拆解',id:30,isActive:false},
	],
	
}
