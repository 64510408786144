import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router';

const routes = [
	{
	    path: '/',
	    name: 'index',
	    component: () => import('../../src/views/index.vue'),
		redirect:'/home',
	    children:[
			{
			    path: '/home',
			    name: 'home',
			    component: () => import('../../src/views/home.vue'),
			    meta: {
			        title: '首页',
			        isLogin: false,
			    }
			},
			{
			    path: '/spot',
			    name: 'spot',
			    component: () => import('../../src/views/spot/index.vue'),
			    meta: {
			        title: '挂牌',
			        isLogin: false,
					index:1
			    }
			},
			{
			    path: '/spotDetail',
			    name: 'spotDetail',
			    component: () => import('../../src/views/spot/detail.vue'),
			    meta: {
			        title: '挂牌详情',
			        isLogin: false,
					index:1
			    }
			},
			{
			    path: '/enterprise',
			    name: 'enterprise',
			    component: () => import('../../src/views/enterprise/index.vue'),
			    meta: {
			        title: '企业处置',
			        isLogin: false,
					index:2
			    }
			},
			{
			    path: '/enterpriseDetail',
			    name: 'enterpriseDetail',
			    component: () => import('../../src/views/enterprise/detail.vue'),
			    meta: {
			        title: '企业处置详情',
			        isLogin: false,
					index:2
			    }
			},
			{
			    path: '/bidding',
			    name: 'bidding',
			    component: () => import('../../src/views/bidding/index.vue'),
			    meta: {
			        title: '招标',
			        isLogin: false,
					index:3
			    }
			},
			{
			    path: '/biddingDetail',
			    name: 'biddingDetail',
			    component: () => import('../../src/views/bidding/detail.vue'),
			    meta: {
			        title: '招标详情',
			        isLogin: false,
					index:3
			    }
			},
			{
			    path: '/message',
			    name: 'message',
			    component: () => import('../../src/views/message/message.vue'),
			    meta: {
			        title: '行业咨询',
			        isLogin: false,
					index:4
			    }
			},
			{
			    path: '/messageDetail',
			    name: 'messageDetail',
			    component: () => import('../../src/views/message/detail.vue'),
			    meta: {
			        title: '行业咨询详情',
			        isLogin: false,
					index:4
			    }
			},
			{
			    path: '/DataService',
			    name: 'DataService',
			    component: () => import('../../src/views/DataService/DataService.vue'),
			    meta: {
			        title: '数据服务',
			        isLogin: false,
					index:5
			    }
			},
			{
			    path: '/DataServiceDetail',
			    name: 'DataServiceDetail',
			    component: () => import('../../src/views/DataService/DataServiceDetail.vue'),
			    meta: {
			        title: '数据服务详情',
			        isLogin: false,
					index:5
			    }
			},
			{
			    path: '/auction',
			    name: 'auction',
			    component: () => import('../../src/views/auction/index.vue'),
			    meta: {
			        title: '拍卖',
			        isLogin: false,
					index:7
			    }
			},
			{
			    path: '/auctionDetail',
			    name: 'auctionDetail',
			    component: () => import('../../src/views/auction/detail.vue'),
			    meta: {
			        title: '拍卖详情',
			        isLogin: false,
					index:7
			    }
			},
			// 个人中心
			{
			    path: '/Centre',
			    name: 'Centre',
			    component: () => import('../../src/views/Centre/Centre.vue'),
				redirect: '/Centre/my',
				children:[
					{
					    path: '/Centre/my',
					    name: 'my',
					    component: () => import('../../src/views/Centre/my.vue'),
					    meta: {
					        title: '会员中心',
					        isLogin: true,
							index:6
					    }
					},
					{
					    path: '/Centre/personage',
					    name: 'personage',
					    component: () => import('../../src/views/Centre/personage.vue'),
					    meta: {
					        title: '个人资料',
					        isLogin: true,
							index:6
					    }
					},
					{
					    path: '/Centre/editPassword',
					    name: 'editPassword',
					    component: () => import('../../src/views/Centre/editPassword.vue'),
					    meta: {
					        title: '修改密码',
					        isLogin: true,
							index:6
					    }
					},
					{
					    path: '/Centre/verify',
					    name: 'verify',
					    component: () => import('../../src/views/Centre/verify.vue'),
					    meta: {
					        title: '身份证验证',
					        isLogin: true,
							index:6
					    }
					},
					{
					    path: '/Centre/submitService',
					    name: 'submitService',
					    component: () => import('../../src/views/Centre/dataService.vue'),
					    meta: {
					        title: '数据服务',
					        isLogin: true,
							index:6
					    }
					},
					// children
					
				]
			  
			},
			
			
		]
	},
	{
	    path: '/login',
	    name: 'login',
	    component: () => import('../../src/views/login.vue'),
	    meta: {
	        title: '登录',
	        isLogin: false,
	    }
	},
	{
	    path: '/register',
	    name: 'register',
	    component: () => import('../../src/views/register.vue'),
	    meta: {
	        title: '注册',
	        isLogin: false,
	    }
	},
	{
	    path: '/:pathMatch(.*)*',
	    name: '404',
	    component: () => import('../../src/views/404.vue'),
	    meta: {
	        title: '404',
	        isLogin: false,
	    }
	},
	{
	    path: '/password',
	    name: 'password',
	    component: () => import('../../src/views/password.vue'),
	    meta: {
	        title: '忘记密码',
	        isLogin: false,
	    }
	},
	
];

const router = createRouter({
    routes,
    // 路由模式
    // history: createWebHashHistory()    // hash 模式
    history: createWebHistory()  // history 模式
});

export default router;
