import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue";
import _imports_0 from '../static/img/header.png';
const _hoisted_1 = {
  style: {
    "width": "100px",
    "height": "100px"
  }
};
const _hoisted_2 = ["src"];
const _hoisted_3 = {
  key: 1,
  src: _imports_0,
  alt: "",
  style: {
    "width": "100%",
    "height": "100%"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_upload = _resolveComponent("el-upload");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_upload, {
    limit: $props.limit,
    accept: $props.fileType,
    headers: $setup.data.headers,
    action: $props.action,
    "show-file-list": false,
    "on-success": $setup.handleAvatarSuccess,
    "before-upload": $setup.beforeAvatarUpload,
    "on-error": $setup.handleError,
    data: $setup.data.porams
  }, {
    default: _withCtx(() => [$setup.data.imageUrl ? (_openBlock(), _createElementBlock("img", {
      key: 0,
      src: $setup.data.imageUrl,
      style: {
        "width": "100px",
        "height": "100px"
      }
    }, null, 8, _hoisted_2)) : (_openBlock(), _createElementBlock("img", _hoisted_3))]),
    _: 1
  }, 8, ["limit", "accept", "headers", "action", "on-success", "before-upload", "on-error", "data"])]);
}