import { ref, reactive, onMounted } from 'vue';
import { useStore } from 'vuex';
import { ElMessage } from 'element-plus';
import webConfig from '../utils/global.config.js';
export default {
  props: ['limit', 'fileType', 'imageUrl', 'action'],
  setup(props, context) {
    const store = useStore();
    let data = reactive({
      headers: {
        Authorization: "Bearer" + store.state.user.token //获取token数据
      },
      imageUrl: '',
      porams: {
        token: store.state.user.token
      }
    });
    onMounted(() => {
      data.imageUrl = props.imageUrl;
      if (data.imageUrl !== '') {
        data.imageUrl = webConfig.webUrl + data.imageUrl;
      }
    });

    // 上传之前
    function beforeAvatarUpload(rawFile) {}
    ;

    // 上传成功
    function handleAvatarSuccess(response, file, fileList) {
      if (response.code === 1) {
        data.imageUrl = webConfig.webUrl + response.data.url;
        ElMessage({
          message: response.msg,
          type: 'success'
        });
        context.emit('uploadSuccessEmits', response.data.url);
      }
    }
    ;

    // 上传失败
    function handleError(response) {
      ElMessage.error('上传失败');
    }
    ;
    return {
      data,
      handleAvatarSuccess,
      beforeAvatarUpload,
      handleError
    };
  }
};