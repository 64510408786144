import router from './router';
import stort from '../store/index.js';
router.beforeEach((to,from,next) => {
	if (!stort.state.user.token) {
		
		// 未登录  页面是否需要登录 根据meta标签的   isLogin==true 是需要登录的
		if (to.meta.isLogin === true) {
			
			next('/login')
		}else{
			window.scrollTo(0, 0);//跳转页面后直接到顶部
			next()
		}
	}else{
		// 用户已经登录  放行
		if (to.path === '/login') {
			if (stort.state.user.token === '' || stort.state.user.token === null) {
				next('/login');
			}else{
				next('/Centre');
			}
			 
		}else{
			next()
		}
		
	}
})