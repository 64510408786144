import "core-js/modules/es.array.push.js";
import { reactive, onBeforeMount, onMounted, computed, watch, onUnmounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import $bus from "../utils/bus.js";
export default {
  name: 'navber',
  setup(props, context) {
    const router = useRouter(); // 等同于 Vue2 中的 this.$router
    const route = useRoute(); // 等同于 Vue2 中的 this.$route
    const store = useStore();
    let data = reactive({
      nav: [{
        id: 0,
        isacTuve: true,
        txt: '首页',
        url: '/home'
      }, {
        id: 1,
        isacTuve: false,
        txt: '挂牌',
        url: '/spot'
      }, {
        id: 2,
        isacTuve: false,
        txt: '企业处置',
        url: '/enterprise'
      }, {
        id: 3,
        isacTuve: false,
        txt: '招标',
        url: '/bidding'
      }, {
        id: 4,
        isacTuve: false,
        txt: '拍卖',
        url: '/auction'
      }, {
        id: 5,
        isacTuve: false,
        txt: '行业资讯',
        url: '/message'
      }, {
        id: 6,
        isacTuve: false,
        txt: '数据服务',
        url: '/DataService'
      }

      // {id:4,isacTuve:false,txt:'企业库',url:''},
      // {id:5,isacTuve:false,txt:'数据服务',url:''},
      // {id:6,isacTuve:false,txt:'行业咨询',url:''}
      ],
      i: null,
      input: ''
    });
    // 监听路由，菜单高亮
    watch(() => route.path, (newValue, oldValue) => {
      data.i = setTimeout(() => {
        // console.log('route',route)
        if (route.path == '/home') {
          data.nav.forEach(item => {
            item.isacTuve = false;
          });
          data.nav[0].isacTuve = true;
        } else if (route.path.indexOf('/spot') !== -1) {
          data.nav.forEach(item => {
            item.isacTuve = false;
          });
          data.nav[1].isacTuve = true;
        } else if (route.path.indexOf('/enterprise') !== -1) {
          data.nav.forEach(item => {
            item.isacTuve = false;
          });
          data.nav[2].isacTuve = true;
        } else if (route.path.indexOf('/bidding') !== -1) {
          data.nav.forEach(item => {
            item.isacTuve = false;
          });
          data.nav[3].isacTuve = true;
        } else if (route.path.indexOf('/auction') !== -1) {
          data.nav.forEach(item => {
            item.isacTuve = false;
          });
          data.nav[4].isacTuve = true;
        } else if (route.path.indexOf('/message') !== -1) {
          data.nav.forEach(item => {
            item.isacTuve = false;
          });
          data.nav[5].isacTuve = true;
        } else if (route.path.indexOf('/DataService') !== -1) {
          data.nav.forEach(item => {
            item.isacTuve = false;
          });
          data.nav[6].isacTuve = true;
        }
      }, 500);
    }, {
      deep: true
    });
    onMounted(() => {
      activeMenu();
    });
    onUnmounted(() => {
      clearTimeout(data.i);
      data.i = null;
    });

    /**
     * 点击导航栏菜单事件
     */
    function onClickNav(item) {
      if (item.isacTuve === false) {
        data.nav.forEach(item => {
          item.isacTuve = false;
        });
      }
      item.isacTuve = true;
      router.push({
        path: item.url
      });
    }
    ;

    // 菜单高亮
    function activeMenu() {
      data.nav.forEach(item => {
        item.isacTuve = false;
      });
      data.i = setTimeout(() => {
        // console.log('route',route)
        if (route.path == '/home') {
          data.nav.forEach(item => {
            item.isacTuve = false;
          });
          data.nav[0].isacTuve = true;
        } else if (route.meta.index == 1 || route.path.indexOf('/spot') !== -1) {
          data.nav.forEach(item => {
            item.isacTuve = false;
          });
          data.nav[1].isacTuve = true;
        } else if (route.meta.index == 2 || route.path.indexOf('/enterprise') !== -1) {
          data.nav.forEach(item => {
            item.isacTuve = false;
          });
          data.nav[2].isacTuve = true;
        } else if (route.meta.index == 3 || route.path.indexOf('/bidding') !== -1) {
          data.nav.forEach(item => {
            item.isacTuve = false;
          });
          data.nav[3].isacTuve = true;
        } else if (route.meta.index == 7 || route.path.indexOf('/auction') !== -1) {
          data.nav.forEach(item => {
            item.isacTuve = false;
          });
          data.nav[4].isacTuve = true;
        } else if (route.meta.index == 4 || route.path.indexOf('/message') !== -1) {
          data.nav.forEach(item => {
            item.isacTuve = false;
          });
          data.nav[5].isacTuve = true;
        } else if (route.meta.index == 4 || route.path.indexOf('/DataService') !== -1) {
          data.nav.forEach(item => {
            item.isacTuve = false;
          });
          data.nav[6].isacTuve = true;
        }
      }, 500);
    }
    ;
    function onBidding() {
      console.log('onBidding', data.input);
      if (data.input === '') {
        router.push({
          path: '/bidding'
        });
      } else {
        router.push({
          path: '/bidding',
          query: {
            input: data.input
          }
        });
      }
    }
    ;
    function onblur() {}
    ;
    return {
      data,
      onClickNav,
      activeMenu,
      onBidding,
      onblur
    };
  }
};