import { createApp } from 'vue';
import App from './App.vue';
import '../src/static/css/quanJu.css';
import 'animate.css/animate.min.css' ;
import 'element-plus/dist/index.css';//弹窗样式
import * as ElementPlusIconsVue from '@element-plus/icons-vue';
// 引入路由

import router from './router/router.js';
// 引入路由权限
import './router/permission.js'

// 导航栏菜单
import SubAside from './components/subAside.vue';


import './assets/iconfont/iconfont.js'
import SvgIcon from './components/SvgIcon.vue';
import upload from './components/upload.vue';
import fileupload from './components/fileUpload.vue';
// 引入vuex
import store from "./store/index.js";


var VueScrollTo = require('vue-scrollto');
const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
app.use(VueScrollTo)
app.use(router)
app.use(store)
app.component('SubAside', SubAside)
app.component('SvgIcon', SvgIcon)
app.component('upload', upload)
app.component('fileupload', fileupload)
app.mount('#app')

