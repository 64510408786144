import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createCommentVNode as _createCommentVNode, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-6487fa99"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  slot: "title",
  style: {
    "margin-left": "15px"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SubAside = _resolveComponent("SubAside", true);
  const _component_el_sub_menu = _resolveComponent("el-sub-menu");
  const _component_SvgIcon = _resolveComponent("SvgIcon");
  const _component_el_menu_item = _resolveComponent("el-menu-item");
  return _openBlock(), _createElementBlock("div", null, [$props.menu.chilren ? (_openBlock(), _createBlock(_component_el_sub_menu, {
    key: 0,
    index: $props.menu.path
  }, {
    title: _withCtx(() => [_createElementVNode("span", null, _toDisplayString($props.menu ? $props.menu.meta.title : ''), 1)]),
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.menu.chilren, item => {
      return _openBlock(), _createBlock(_component_SubAside, {
        menu: item
      }, null, 8, ["menu"]);
    }), 256))]),
    _: 1
  }, 8, ["index"])) : (_openBlock(), _createBlock(_component_el_menu_item, {
    key: 1,
    index: $props.menu.path
  }, {
    default: _withCtx(() => [_createVNode(_component_SvgIcon, {
      iconName: $props.menu.meta.icon,
      size: "20px"
    }, null, 8, ["iconName"]), _createElementVNode("span", _hoisted_1, _toDisplayString($props.menu ? $props.menu.meta.title : ''), 1)]),
    _: 1
  }, 8, ["index"]))]);
}