// 封装模块 使用localStorage实现持久化token信息 只是进行保存
// 从localStorage中取出一项数据 名字叫name
export const getItem = name => {
  return JSON.parse(localStorage.getItem(name))
}
// 向localStorage中设置一项数据 名字为name里面设置值为obj
export const setItem = (name, obj) => {
  localStorage.setItem(name, JSON.stringify(obj))
}
// 删除
export const removeitem = name => {
  localStorage.removeItem(name)
}







// 存储登录的userinfo 所有信息
export const getUserInfo = name => {
  return JSON.parse(localStorage.getItem(name))
}

export const setUserInfo = (name, obj) => {
  localStorage.setItem(name, JSON.stringify(obj))
}

export const removeUserInfo = name => {
  localStorage.removeItem(name)
}



