// 登录保存信息
import { setItem, getItem,removeitem,getUserInfo,setUserInfo,removeUserInfo } from '@/utils/storage.js';
const user = {
	namespaced:true,
	state:{
		// 保存公共数据 在设置vuex中的初值时，先从本地存储中取，如果取不到，则初始为空
		token:getItem('token') || null,
		userInfo:getUserInfo('userinfo') || {}
	},
	mutations:{
		SET_TOKEN_INFO (state,tokenObj) {
			state.token = tokenObj
			// 因为刷新会丢失所以进行持久化 调用storage文件里方法
			setItem('token', tokenObj)
		},
		// 退出删除token
		REMOVE_TOKEN_INFO (state,tokenObj) {
			state.token = tokenObj
			removeitem('token')
		},
		
		SET_USER_INFO (state,userinfo) {
			state.userInfo = userinfo;
			setUserInfo('userinfo',userinfo);
		},
		REMOVE_USER_INFO (state,userinfo) {
			state.userInfo = userinfo;
			removeitem('userinfo')
		}
		
		
	},
	actions:{
		
	}
}



export default user