import { ref, reactive, onMounted, toRef } from 'vue';
import { useStore } from 'vuex';
import { ElMessage } from 'element-plus';
import webConfig from '../utils/global.config.js';
export default {
  props: ['limit', 'fileType', 'imageUrl', 'action'],
  setup(props, context) {
    const store = useStore();
    let data = reactive({
      headers: {
        Authorization: "Bearer" + store.state.user.token //获取token数据
      },
      fileList: [],
      action: props.action,
      porams: {
        token: store.state.user.token
      }
    });

    // 上传成功
    function handleAvatarSuccess(response, file, fileList) {
      if (response.code === 1) {
        ElMessage({
          message: response.msg,
          type: 'success'
        });
        context.emit('uploadSuccess', response.data.url);
      }
    }
    ;

    // 上传失败
    function handleError(response) {
      ElMessage.error('上传失败');
    }
    ;

    // 删除
    function beforeRemove(uploadFile, uploadFiles) {
      context.emit('uploadSuccess', '');
    }
    ;

    // 点击已经上传
    function handlePreview(uploadFile) {
      open(webConfig.webUrl + uploadFile.response.data.url, '_blank');
    }
    ;
    return {
      data,
      handleAvatarSuccess,
      handleError,
      beforeRemove,
      handlePreview
    };
  }
};